import React, { useEffect, useState } from "react";
import Image from "next/image";
import { gsap } from "gsap";
import { useMediaQuery } from 'react-responsive';

// Desktop images
import GrahicpDesigner from "../../assests/home_page/services/Graphic_Design.png";
import Ellipse_red from "../../assests/home_page/services/Ellipse_red.png";
import Ellipse_blue from "../../assests/home_page/services/Ellipse_blue.png";
import Ellipse_yellow from "../../assests/home_page/services/Ellipse_yellow.png";
import Ellipse_green from "../../assests/home_page/services/Ellipse_green.png";

// Mobile images
import green_mobile from "../../assests/home_page/services/green_mobile.png";
import yellow_mobile from "../../assests/home_page/services/yellow_mobile.png";
import orange_mobile from "../../assests/home_page/services/orange_mobile.png";
import purple_mobile from "../../assests/home_page/services/purple_mobile.png";
import yellowMobile from "../../assests/home_page/services/yellow-mobile.png";

import UI_UX_desgigner from "../../assests/home_page/services/UI_UX_desgigner.png";
import Digital_marketings from "../../assests/home_page/services/Digital_marketings.png";
import Copy_writer from "../../assests/home_page/services/Copy_writer.png";
import Video_productions from "../../assests/home_page/services/Video+productions.png";

const Resources = () => {
  const isMobile = useMediaQuery({ maxWidth: 768, minWidth: 320 });

  const [resources, setResources] = useState([]);

  useEffect(() => {
    setResources([
      {
        title: "Graphic",
        span: "Design",
        image: GrahicpDesigner,
        ellipse: isMobile ? orange_mobile : Ellipse_red,
      },
      {
        title: "UI/UX",
        span: "Design",
        image: UI_UX_desgigner,
        ellipse: isMobile ? yellow_mobile : Ellipse_yellow,
      },
      {
        title: "Copywriting",
        span: "",
        image: Copy_writer,
        ellipse: isMobile ? purple_mobile : Ellipse_blue,
      },
      {
        title: "Digital ",
        span: "Marketing",
        image: Digital_marketings,
        ellipse: isMobile ? green_mobile : Ellipse_green,
      },
      {
        title: "Video",
        span: "Production",
        image: Video_productions,
        ellipse: isMobile ? yellowMobile : Ellipse_yellow,
      },
    ]);
  }, [isMobile]);

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 0.1 });
    const ellipse = gsap.timeline({ repeat: -1, repeatDelay: 0.1 });

    resources.forEach((item, index) => {
      tl.fromTo(
        `#resource_${index}`,
        {
          translate: "1900px ",
          rotate: 110,
          opacity: 1,
          ease: "elastic.out(1, 0.9)",
        },
        {
          opacity: 1,
          ease: "elastic.out(1, 0.7)",
          translate: "0px",
          duration: 2,
          rotate: 0,
          mixBlendMode: "multiply",
        }
      ).to(`#resource_${index}`, {
        translate: "-2000px ",
        duration: 1,
        opacity: 0,
        rotate: -180,
        ease: "elastic.out(1, 0.9)",
        delay: 0.9,

      });

      ellipse.fromTo(
        `#ellipse_${index}`,
        {
          opacity: 0,
          transform: "0px 200px",
          duration: 3.8,
        },
        {
          opacity: 1,
          ease: "elastic.out(1, 0.7)",
          duration: 3.8,


        }
      ).to(`#ellipse_${index}`, {
        duration: 0.2,
        opacity: 0,

      });
    });

    // tl.play();
    // ellipse.play();

    return () => {
      tl.kill();
      ellipse.kill();
    };
  }, [resources]);


  return (
    <div className="relative sm:min-h-[90vh] min-h-[40vh]">
      <div className="flex sm:my-24 my-12 justify-center items-center">
        <h1 className="text-[#5C489C] sm:text-9xl cursor-default text-7xl font-extrabold leading-[74px]">
          Resources
        </h1>
      </div>
      <div className="container mx-auto pt-12">
        <div className="flex items-center justify-center">
          {resources.map((item, index) => (
            <div key={index} className="absolute sm:top-[25%] top-[37%]">
              <div
                id={`resource_${index}`}
                className="flex mx-auto items-center justify-center gap-20 px-12 sm:px-24"
              >
                <div className="sm:text-[90px] sm:leading-[93px] sm:text-left text-center text-5xl font-extrabold text-[#E65125] sm:w-full w-[30%]">
                  {item?.title} <br />
                  <span className="font-medium sm:leading-[93px] sm:text-[93px] text-4xl">
                    {item?.span}
                  </span>
                </div>
                <div className="sm:w-full w-[45%]">
                  <Image
                    src={item?.image}
                    className="mix-blend-multiply resource_image"
                    alt={item?.title}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        {resources.map((item, index) => (
          <div
            key={index}
            id={`ellipse_${index}`}
            className="absolute bottom-0 w-full sm:left-1/2 sm:-translate-x-1/2"
          >
            <Image
              src={item?.ellipse}
              className="sm:object-fill object-contain"
              alt="graphic_designer"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Resources;
